// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  subdomain: 'dev_',
  domain: 'development.megapay.com.ph',
/*   subdomain: 'clnwebintranet_',
  domain: 'clnwebintranet.synergies.app', */
  production: false,
  //test
  api_url: 'https://backend.megapay.com.ph:444/megapayportalbackend/api',
  // api_url: 'https://development.megapay.com.ph:444/megapayportalbackend/api',
  firebaseConfig: {
    apiKey: "AIzaSyDqW9cYnR_Kqw8KJcGtsF14QXwnlYaz7c4",
    authDomain: "cln-firebase-452d4.firebaseapp.com",
    databaseURL: "https://cln-firebase-452d4-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "cln-firebase-452d4",
    storageBucket: "cln-firebase-452d4.appspot.com",
    messagingSenderId: "963937838975",
    appId: "1:963937838975:web:2d892c97589bc89eb35c4a"
  },
  fcm_server_key: 'AAAA4G8sJ38:APA91bHfUeHPAWfEYjdTWXT7NAaUe7bVRQ2uP0P6GPUWEJG1lPwRycrIAZIGb9B28VrJ7laz6iBliBh9Ds4m4zX_HXUVyQlUwqkjSvcwg0AVcKckWb4wZYt39TlhY4onQjO29Ledopyj',
  build_type: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
